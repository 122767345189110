.frame-type-wvier_quote {
    position: relative;
    text-align: right;
    display: flex;
    justify-content: center;
    max-height: 400px;
    overflow: hidden;

    picture {
        display: block;

        img {
            width: 100%;
            height: auto;
        }
    }

    .headline {
        font-size: $quote-headline-font-size;
        font-family: $headings-font-family;
        color: $quote-text-color;
        font-weight: $font-weight-bold;
        margin-bottom: 15px;
        display: block;
        text-shadow: 2px 2px 4px rgba(0,0,0,0.6);
        line-height: 1.25;
    }

    .w4-quote-autor {
        color: $quote-text-color;
        text-transform: uppercase;
        font-weight: 500;
        font-size: $quote-autor-font-size;
        text-shadow: 2px 2px 4px rgba(0,0,0,0.4);
    }

    @include media-breakpoint-up(lg) {
        .headline {
            font-size: $quote-headline-font-size-lg;
            width: 66.67%;
            line-height: 1.2;
        }

        .w4-quote-autor {
            font-size: $quote-autor-font-size-lg;
        }
    }

    @include media-breakpoint-up(xl) {
        .headline {
            font-size: $quote-headline-font-size-xl;
        }
    }

    .quote-caption {
        position: absolute;
        left: 0;
        right: 0;
        display: flex;
        height: 100%;
        flex-direction: column;
        justify-content: center;
        align-items: flex-end;
    }

    .quote-image {
        filter: brightness(0.8);
        width: 100%;
    }
}
